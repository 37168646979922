import axios from 'axios';
import GenericService from './GenericService';
import { SETTINGS } from '../config/settings';

class UserService extends GenericService {
    login = (username, password, nonce, token, refresh) =>
        new Promise(async (resolve, reject) => {
            let url = `https://login.xsolla.com/api/oauth2/login/token?client_id=3324&scope=offline`;
            let data = {
                username,
                password,
            };
            let headers = {
                'Content-Type': 'application/json',
            };
            if (refresh) {
                url = `https://login.xsolla.com/api/oauth2/token`;
                data = new URLSearchParams({
                    client_id: '3324',
                    grant_type: 'refresh_token',
                    refresh_token: refresh,
                }).toString(); // Convert to URL-encoded string
                headers = {
                    'Content-Type': 'application/x-www-form-urlencoded',
                };
            }
            try {
                let x_data = await axios.post(url, data, { headers });
                x_data = x_data.data;

                this.post('user/login/v2?nonce=' + nonce, {
                    access_token: x_data.access_token,
                    token,
                })
                    .then((data) => {
                        localStorage.setItem('token', data.token);
                        localStorage.setItem('xtoken', x_data.access_token);
                        localStorage.setItem('refresh_token', x_data.refresh_token);
                        localStorage.setItem('login_time', new Date().getTime());
                        this.tokenUpdate();
                        resolve(data);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            } catch (err) {
                reject(err);
            }
        });

    logout = () =>
        new Promise((resolve) => {
            this.post('user/logout')
                .then(() => {
                    localStorage.removeItem('user');
                    localStorage.removeItem('token');
                    localStorage.removeItem('xtoken');
                    localStorage.removeItem('refresh_token');
                    localStorage.removeItem('login_time');
                    this.tokenUpdate();
                    resolve();
                })
                .catch((err) => {
                    localStorage.removeItem('user');
                    localStorage.removeItem('token');
                    localStorage.removeItem('xtoken');
                    localStorage.removeItem('refresh_token');
                    localStorage.removeItem('login_time');
                    this.tokenUpdate();
                    console.error(err);
                    resolve();
                });
        });

    isLoggedIn = async () =>
        new Promise((resolve, reject) => {
            if (localStorage.getItem('refresh_token') != null) {
                let login_time = localStorage.getItem('login_time') ?? 0;
                let current_time = new Date().getTime();
                let diff = current_time - +login_time;
                // If user is logged in for more than 1 day, logout
                if (diff > 86400000) {
                    localStorage.removeItem('user');
                    localStorage.removeItem('token');
                    localStorage.removeItem('xtoken');
                    localStorage.removeItem('refresh_token');
                    localStorage.removeItem('login_time');
                    this.tokenUpdate();
                    reject();
                    return;
                }

                if (!window.opener) {
                    resolve();
                    return;
                }
                this.login(null, null, null, null, localStorage.getItem('refresh_token'))
                    .then(resolve)
                    .catch(reject);
            } else {
                reject();
            }
        });

    getLoggedInUser = this.getMe;

    getMe = () =>
        new Promise((resolve, reject) => {
            this.tokenUpdate();
            this.get(`user/me`)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });

    getMyPoints = (game, server) =>
        new Promise((resolve, reject) => {
            this.tokenUpdate();
            this.get(`user/me/points?game=${game}&server=${server}`)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });

    updateMe = (data) =>
        new Promise((resolve, reject) => {
            this.tokenUpdate();
            this.put(`user/me`, data)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });

    updateEmail = (data) =>
        new Promise((resolve, reject) => {
            this.tokenUpdate();
            this.put(`user/me/email`, data)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });

    forgetPassword = (data) => this.post('user/password/reset/request', data);

    resetPassword = (data) =>
        new Promise((resolve, reject) => {
            this.tokenUpdate();
            this.post('user/password/reset/confirm', data)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });

    register = (data) =>
        new Promise((resolve, reject) => {
            this.post('user', data)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });

    migration = (data) => {
        this.tokenUpdate();
        return this.post('migration', data);
    };

    getMigrations = () => {
        this.tokenUpdate();
        return this.get('migration');
    };

    freshdeskLogin = (nonce) => {
        this.tokenUpdate();
        return this.post(`user/freshdesk`, {
            nonce: nonce,
        });
    };

    getRewards = (gameId) =>
        new Promise((resolve, reject) => {
            this.tokenUpdate();
            this.get(`loyalty?game=` + gameId)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });

    claimReward = (data) => {
        return new Promise((resolve, reject) => {
            this.tokenUpdate();
            this.post(`loyalty`, data)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    resendConfirmation = (username) =>
        new Promise((resolve, reject) => {
            axios
                .post(
                    `https://login.xsolla.com/api/user/resend_confirmation_link?projectId=7fc91f39-8efe-443a-9cbe-06a6786de605&login_url=${SETTINGS.REDIRECT_URL}`,
                    { username },
                )
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
}

const userService = new UserService();
export default userService;
