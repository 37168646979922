/*
    Imports
*/
import { Icon } from '@iconify/react';
import {
    Alert,
    Button,
    Collapse,
    Grid,
    IconButton,
    InputAdornment,
    Link,
    Stack,
    TextField,
} from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link as RouterLink, useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import LoadingFormButton from 'src/components/misc/Buttons/LoadingFormButton';
import { LoginSchema } from 'src/config/form-schemas';
import { HidePasswordIcon, PasswordIcon, ShowPasswordIcon, UsersIcon } from 'src/config/icons';
import { RouteForgetPass, RouteLandingPage, RouteRegister } from 'src/config/routes';
import { SETTINGS } from 'src/config/settings';
import userService from 'src/services/UserServiceClass';
import palette from 'src/theme/palette';
import { SendLoginTokensToParent } from 'src/utils/misc';

/*
    Main Working
*/
export default ({}) => {
    /*
      States, Params, Navigation, Query.
    */
    const [serverError, setServerError] = useState('');
    const [serverInfo, setServerInfo] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [openedWindow, setOpenedWindow] = useState(null);

    const navigate = useNavigate();
    const location = useLocation();

    const captchaRef = useRef(null);

    const [searchParams, setSearchParams] = useSearchParams();

    // Check for confirmation token
    useEffect(() => {
        const token = searchParams.get('token');
        if (token) {
            setServerInfo('Please log in to complete your account confirmation process.');
        }
    }, [searchParams]);

    const verify = () => {
        captchaRef.current.getResponse().then((res) => {
            setCaptchaToken(res);
        });
    };

    /*
      Form Setup
    */
    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
        },
        validationSchema: LoginSchema,
        onSubmit: (values, { setSubmitting, setFieldError }) => {
            const token = captchaRef.current.getValue();
            if (!token) {
                setServerError('Please verify that you are not a robot');
                setSubmitting(false);
                return;
            }

            userService
                .login(values.username, values.password, searchParams.get('nonce'), token)
                .then((_data) => {
                    if (searchParams.get('nonce')) {
                        window.location.href = `${searchParams.get(
                            'redirect_uri',
                        )}?state=${searchParams.get('state')}&id_token=${_data.token}`;
                        return;
                    }

                    if (openedWindow) {
                        SendLoginTokensToParent(openedWindow);
                        return;
                    }

                    navigate(location.state?.from ? location.state.from : RouteLandingPage, {
                        replace: true,
                    });
                    //navigate(RouteDashboard);
                })
                .catch((err) => {
                    console.error('Error', err);
                    setSubmitting(false);
                    if (!err.response) {
                        setServerError('Error occurred please try later');
                    } else {
                        const errorMsg =
                            err.response.data.msg || err.response.data.error.description;
                        if (errorMsg.toLowerCase().includes('not confirmed')) {
                            // Resend confirmation email
                            userService
                                .resendConfirmation(values.username)
                                .then(() => {
                                    setServerError(
                                        'Your account is not confirmed. A new confirmation email has been sent to your registered email address. Please check your inbox and confirm your account before logging in.',
                                    );
                                })
                                .catch((resendErr) => {
                                    setServerError(
                                        'Your account is not confirmed. We attempted to send a new confirmation email but encountered an error. Please try again later or contact support.',
                                    );
                                });
                        } else {
                            setServerError(errorMsg);
                        }
                    }
                })
                .finally(() => {
                    captchaRef.current?.reset();
                });
        },
    });
    const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

    /*
      Handlers
    */

    const handleShowPassword = () => {
        setShowPassword((show) => !show);
    };

    /*
      Use Effect Hooks.
    */

    useEffect(() => {
        if (window.opener) {
            setOpenedWindow(window.opener);
        }
    }, [window.opener]);

    /*
      Main Design
    */
    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3}>
                    <TextField
                        fullWidth
                        autoComplete="current-username"
                        type="text"
                        label="Username or Email"
                        {...getFieldProps('username')}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Icon icon={UsersIcon} inline="true" style={{ fontSize: 20 }} />
                                </InputAdornment>
                            ),
                        }}
                        error={Boolean(touched.username && errors.username)}
                        helperText={touched.username && errors.username}
                    />

                    <br />

                    <TextField
                        fullWidth
                        autoComplete="current-password"
                        type={showPassword ? 'text' : 'password'}
                        label="Password"
                        {...getFieldProps('password')}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={handleShowPassword} edge="end">
                                        <Icon
                                            icon={
                                                showPassword ? HidePasswordIcon : ShowPasswordIcon
                                            }
                                        />
                                    </IconButton>
                                </InputAdornment>
                            ),
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Icon
                                        icon={PasswordIcon}
                                        inline="true"
                                        style={{ fontSize: 20 }}
                                    />
                                </InputAdornment>
                            ),
                        }}
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                    />
                    <ReCAPTCHA
                        sitekey={SETTINGS.RECAPTCHA.SITE_KEY}
                        ref={captchaRef}
                        onVerify={verify}
                        theme="dark"
                    />

                    <br />
                    {!isSubmitting && !openedWindow && (
                        <Link
                            component={RouterLink}
                            color={palette.error.light}
                            style={{ textDecoration: 'none', textAlign: 'right' }}
                            variant="subtitle2"
                            to={RouteForgetPass}
                        >
                            Password recovery
                        </Link>
                    )}
                </Stack>

                <br />

                <Grid container spacing={{ md: 4 }}>
                    <Grid item xs={12} sm={6} md={6} style={{ marginBottom: 20 }}>
                        <LoadingFormButton
                            style={{ padding: '0px 70px' }}
                            size="large"
                            type="submit"
                            variant="contained"
                            loading={isSubmitting}
                        >
                            Login
                        </LoadingFormButton>
                    </Grid>
                    {/* {!openedWindow && ( */}
                    <Grid item xs={12} sm={6} md={6} style={{ marginBottom: 20 }}>
                        <Button
                            style={{ padding: '0px 58px' }}
                            size="large"
                            type="submit"
                            variant="outlined"
                            loading={isSubmitting.toString()}
                            href={RouteRegister}
                            // Open in new tab
                            target={openedWindow ? '_blank' : ''}
                        >
                            Register
                        </Button>
                    </Grid>
                    {/* )} */}
                </Grid>

                {!!serverError && (
                    <Stack marginTop={3}>
                        <Collapse in={!!serverError}>
                            <Alert severity="error">{serverError}</Alert>
                        </Collapse>
                    </Stack>
                )}

                {!!serverInfo && (
                    <Stack marginTop={3}>
                        <Collapse in={!!serverInfo}>
                            <Alert severity="info">{serverInfo}</Alert>
                        </Collapse>
                    </Stack>
                )}
            </Form>
        </FormikProvider>
    );
};
