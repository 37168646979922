/*
  Imports
*/
import { Container, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import ServerError from 'src/components/misc/alerts/ServerError';
import ListPageTitle from 'src/components/misc/ListPageTitle';
import { RouteDiscord, RouteNews } from 'src/config/routes';
import { SETTINGS } from 'src/config/settings';
import userService from 'src/services/UserServiceClass';
import Page from '../components/Page';
import MigrationForm from './forms/MigrationForm';
/*
  Main Working
*/
export default () => {
    const title = `Account Migration`;

    const [migrations, setMigrations] = useState([]);

    useEffect(() => {
        userService.getMigrations().then((res) => {
            let unique = res.filter((v, i, a) => a.findIndex((t) => t.Game === v.Game) === i);
            setMigrations(unique);
        });
    }, []);

    return (
        <Page title={title}>
            <Container>
                <ListPageTitle mb={5}>{title}</ListPageTitle>

                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottomWidth: 0.1, borderBottom: 1 }}>
                        <Typography sx={{ marginBottom: 5, marginTop: -4 }}>
                            Use the account migration to link your Fawkes' games account with your
                            progress in a game we are operating. In case of questions, do not
                            hesitate to contact us via Support or Discord.
                        </Typography>
                        <ServerError open={true} sx={{ width: '100%' }}>
                            Make sure you are not logged into the game while requesting the
                            migration.
                        </ServerError>
                        <ServerError open={true} sx={{ width: '100%' }}>
                            Create a new account for migration. Do not migrate your data to an
                            active account, as this will result in the loss of all progress on that
                            account.
                        </ServerError>
                        <br />
                    </Box>
                    <MigrationForm />
                    <Grid container spacing={1}>
                        {migrations.map((migration, i) => {
                            return (
                                <Grid item xs={12} md={12} lg={12} key={`grid-migration-${i}`}>
                                    <ServerError
                                        open
                                        sx={{ width: '100%' }}
                                        sxA={{ backgroundColor: '#22B14C', color: 'white' }}
                                        severity="success"
                                        icon={false}
                                    >
                                        <b>{SETTINGS.GAMES[migration.Game].name}</b> code
                                        successfully claimed.
                                    </ServerError>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Box>
            </Container>
        </Page>
    );
};
